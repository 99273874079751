import { AsyncPipe, CommonModule, DatePipe, Location } from '@angular/common'
import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    inject,
    input,
    signal,
} from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { AlertService } from '@core/services/alert.service'
import { ThemeService } from '@core/services/theme.service'
import { AppStateService } from '@core/states/app-state.service'
import { TaigaUiImports } from '@core/ui/taiga-ui-imports'
import { ParagraphMenuComponent } from '@modules/ai-writer/components/paragraph-menu/paragraph-menu.component'
import { ReleaseNote } from '@modules/update/models/release-note.model'
import { ReleaseNoteApiService } from '@modules/update/services/release-note-api.service'
import { ReleaseNoteFormService } from '@modules/update/states/release-note-form.service'
import { ReleaseNoteListStateService } from '@modules/update/states/release-note-list-state.service'
import { TuiDay } from '@taiga-ui/cdk'
import { TUI_SANITIZER } from '@taiga-ui/core'
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify'
import dayjs from 'dayjs'
import { Editor, NgxEditorModule, Toolbar } from 'ngx-editor'
import { AdminRoutes, getAdminRoutes } from '../admin.routes'

@Component({
    selector: 'app-application-updates',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ParagraphMenuComponent,
        RouterModule,
        AsyncPipe,
        ...TaigaUiImports,
        NgxEditorModule,
    ],
    templateUrl: './application-updates.component.html',
    styleUrls: ['./application-updates.component.scss'],
    providers: [
        {
            provide: TUI_SANITIZER,
            useClass: NgDompurifySanitizer,
        },
        DatePipe,
    ],
})
export class ApplicationUpdatesComponent implements OnInit, OnDestroy {
    private alertService = inject(AlertService)
    releaseNoteFormService = inject(ReleaseNoteFormService)
    releaseNoteApiService = inject(ReleaseNoteApiService)
    releaseNoteListStateService = inject(ReleaseNoteListStateService)
    appStateService = inject(AppStateService)
    themeService = inject(ThemeService)

    @Output() done = new EventEmitter<void>()
    id = input<string>('')

    releaseNote = signal<ReleaseNote | null>(null)

    readonly adminRoutes: AdminRoutes = getAdminRoutes()
    editor: Editor
    toolbar: Toolbar = [
        ['bold', 'italic'],
        ['underline', 'strike'],
        ['code', 'blockquote'],
        ['ordered_list', 'bullet_list'],
        [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
        ['link', 'image'],
        ['text_color', 'background_color'],
        ['align_left', 'align_center', 'align_right', 'align_justify'],
        [],
    ]

    constructor(
        private location: Location,
        private datePipe: DatePipe,
    ) {}
    ngOnInit(): void {
        this.editor = new Editor()
        if (this.id()) {
            this.releaseNoteApiService.getOneReleaseNote(this.id()).subscribe({
                next: (data) => {
                    const d = dayjs.utc(data.data.releaseDate as string)
                    const formattedDate = new TuiDay(d.year(), d.month(), d.date())

                    const releaseNoteData = {
                        ...data.data,
                        releaseDate: formattedDate,
                    }
                    this.releaseNote.set(releaseNoteData)
                    this.releaseNoteListStateService.setState({ releaseNote: releaseNoteData })
                    this.releaseNoteFormService.releaseNoteForm.patchValue(releaseNoteData)
                },
            })
        }
    }

    onSubmit() {
        if (this.id()) {
            this.releaseNoteFormService.updateReleaseNote(this.id())
        } else {
            this.releaseNoteFormService.submitReleaseNote()
        }
    }

    ngOnDestroy(): void {
        this.editor.destroy()
        this.releaseNoteFormService.releaseNoteForm.reset()
    }

    onGoBack() {
        this.location.back()
    }
}
